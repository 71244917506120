
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  watch
} from 'vue'

export default {
  name: '',
  props: {
    isShowDialog: {
      default: false
    },
    voucherCodeId: {
      default: null
    }
  },
  emits: ['hideDialog', 'processLevelup'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props) as any
    const showDialog = propertiesProps.isShowDialog
    const hideDialog = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }

    const processLevelup = () => {
      emit('processLevelup')
    }

    return {
      showDialog,
      hideDialog,
      processLevelup
    }
  }
}
