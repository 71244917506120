import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cutomer_retail = _resolveComponent("cutomer-retail")

  return (_openBlock(), _createBlock("section", null, [
    ($setup.positionTabs == 'customerRetail')
      ? (_openBlock(), _createBlock(_component_cutomer_retail, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}