
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
// import TabsNavCustomer from '@/views/master/customers/customer/components/TabsNavCustomer.vue'
import CutomerRetail from '@/views/master/customers/customer/customer-retail/CustomerRetail.vue'
// import CustomerCompany from '@/views/master/customers/customer/customer-company/CustomerCompany.vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    // TabsNavCustomer,
    CutomerRetail,
    // CustomerCompany
  },

  setup() {
    const route = useRoute() as any
    const checkFirstPosition = () => {
      if (route.meta.permission.company.read && !route.meta.permission.retail.read) {
        return 'customerCompany'
      }
      return 'customerRetail'
    }
    const positionTabs = ref(checkFirstPosition())
    const tabsChange = (val: any) => {
      positionTabs.value = val
      // console.log('masuk change', val)
    }
    return {
      tabsChange,
      route,
      positionTabs
    }
  }
}
